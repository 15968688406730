import React, { useEffect, useRef, useState } from "react";
import Helmet from "react-helmet";
import { Content } from "components/Content";
import TicketShop from "components/TicketShop";
import { List } from "components/List";
import { useIsCompact } from "hooks/useIsCompact";
import { Hero } from "components/Hero";
import { TextBlock } from "components/TextBlock";
import { motion } from "framer-motion";
import { Link } from "gatsby";
import { ticketShopScript } from "constants/ticketShopScript";
import { StaticImage } from "gatsby-plugin-image";
import { Signup } from "components/Signup";

const staticImgProps = {
  alt: "",
  layout: "fullWidth",
  objectFit: "cover",
  objectPosition: "center",
};

const carouselImages = {
  1: {
    node: <StaticImage src="../images/carousel/1.jpg" {...staticImgProps} />,
    src: "/carousel/1.jpg",
  },
  2: {
    node: <StaticImage src="../images/carousel/2.jpg" {...staticImgProps} />,
    src: "/carousel/2.jpg",
  },
  3: {
    node: <StaticImage src="../images/carousel/3.jpg" {...staticImgProps} />,
    src: "/carousel/3.jpg",
  },
  4: {
    node: <StaticImage src="../images/carousel/4.jpg" {...staticImgProps} />,
    src: "/carousel/4.jpg",
  },
  5: {
    node: <StaticImage src="../images/carousel/5.jpg" {...staticImgProps} />,
    src: "/carousel/5.jpg",
  },
  6: {
    node: <StaticImage src="../images/carousel/6.jpg" {...staticImgProps} />,
    src: "/carousel/6.jpg",
  },
};

const variants1 = {
  start: { rotate: -3, y: "-4%", x: "-4%" },
  end: { rotate: 0, y: "0%", x: "0%" },
};
const variants2 = {
  start: { rotate: 7, y: "19%", x: "21%" },
  end: { rotate: 0, y: "14%", x: "18%" },
};

function IndexPage(p) {
  const isCompact = useIsCompact(p.location.search);

  const [animate1, setAnimate1] = useState("start");
  const [animate2, setAnimate2] = useState("start");

  const animate1Timeout = useRef();
  const animate2Timeout = useRef();

  useEffect(() => {
    return () => {
      clearTimeout(animate1Timeout.current);
      clearTimeout(animate2Timeout.current);
    };
  }, []);

  const title = "Status100 Promotions";
  const description = `We are Status100 Promotions, an innovative events company. We organise club events, concerts and manage/organise international tours for artist from various genres.`;

  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{
          lang: "en-GB",
        }}
        title={title}
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            property: `og:url`,
            content: p.location.href,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: description,
          },
          {
            property: `og:image`,
            content: ``,
          },
          {
            name: "facebook-domain-verification",
            content: ``,
          },
        ]}
      />
      <Hero
        carouselImages={[
          carouselImages["1"],
          carouselImages["2"],
          carouselImages["3"],
          carouselImages["4"],
          carouselImages["5"],
          carouselImages["6"],
        ]}
        image={carouselImages["1"]}
      ></Hero>
      <Content type="home__columns">
        {/* <div className="home__columns">
            <div>
              <List>
                <TextBlock>
                  <h2>Status100 Promotions</h2>
                  <p>
                    We are Status100 Promotions, an events company that prides
                    itself on delivering high quality innovative and culturally
                    diverse events.
                  </p>
                  <p>
                    Our premium music marketing and promotion services include
                    organising clubevents, concerts as well as managing
                    international tours for global artists from various genres.
                  </p>
                  <p>
                    We have established ourselves as a trusted partner and
                    delivered quality performances from world renowned artists
                    such as: Davido, Tion Wayne, BurnaBoy, Nutty O, Blue Face,
                    DBN Gogo, Lil Durk, Kabza De Small, Lil TeeJay ,
                    UncleWaffles, DJ Maphorisa and many more.
                  </p>
                  <p>
                    Join our mailing list for notifications on upcoming events
                    and the best experiences.
                  </p>
                </TextBlock>
              </List>
            </div>
            <div className="home__photos">
              <motion.div
                animate={animate1}
                transition={{
                  type: "spring",
                  stiffness: 200,
                  damping: 17,
                  duration: 0.3,
                }}
                variants={variants1}
                onAnimationComplete={() => {
                  animate1Timeout.current = setTimeout(
                    () =>
                      setAnimate1((val) => (val === "start" ? "end" : "start")),
                    1000
                  );
                }}
              >
                <img src="/homePhoto1.png" />
              </motion.div>
              <motion.div
                animate={animate2}
                transition={{
                  type: "spring",
                  stiffness: 200,
                  damping: 16,
                  duration: 0.4,
                }}
                variants={variants2}
                onAnimationComplete={() => {
                  animate2Timeout.current = setTimeout(
                    () =>
                      setAnimate2((val) => (val === "start" ? "end" : "start")),
                    1000
                  );
                }}
              >
                <img src="/homePhoto2.png" />
              </motion.div>
            </div>
          </div> */}
        <TicketShop scriptString={ticketShopScript} />

        <Signup />
      </Content>
    </React.Fragment>
  );
}

export default IndexPage;
