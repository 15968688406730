import { Link } from "gatsby";
import React from "react";

export function Signup() {
  return (
    <div className="signup">
      <h3>SIGN UP</h3>
      <p>
        Sign up for cheaper tickets and early access to tickets before anyone
        else
      </p>
      <Link to="/signup">SIGN UP</Link>
    </div>
  );
}
